import request from "../utils/http";
// import axios from "./httpp"
import { baseUrl } from "@/config/project";
import qs from "qs";

/**
 * 适界开放平台接口
 */
// 登录
export function login(param) {
  return request({
    url: "/user/login",
    method: "post",
    data: param,
  });
}
// 获取图形验证码
export function getCode(param) {
  return request({
    url: "/common/getCaptcha",
    method: "post",
    data: param,
  });
}
// 获取短信验证码
export function getMsgCode(param) {
  return request({
    url: "/common/getVerifyCode",
    method: "post",
    data: param,
  });
}
// 账户注册
export function register(param) {
  return request({
    url: "/user/register",
    method: "post",
    data: param,
  });
}
// 重置密码
export function resetPwd(param) {
  return request({
    url: "/user/resetPwd",
    method: "post",
    data: param,
  });
}
// 退出系统
export function logout(param) {
  return request({
    url: "/user/logout",
    method: "post",
    data: param,
  });
}
// 开发应用-重置密钥
export function resetSecret(param) {
  return request({
    url: "/account/resetDevSecret",
    method: "post",
    data: param,
  });
}
// 权限管理-账号审核
export function operatAccount(param) {
  return request({
    url: "/account/operatAccount",
    method: "post",
    data: param,
  });
}
// 设备管理-获取直播流地址
export function getAddress(param) {
  return request({
    url: "/camera/getAddress",
    method: "post",
    data: param
  });
}
// 设备管理-设置截图回传频率
export function setCameraSnapshot(param) {
  return request({
    url: "/camera/setCameraSnapshot",
    method: "post",
    data: param
  });
}
// 删除设备
export function reomveCamera(param) {
  return request({
    url: "/camera/reomveCamera",
    method: "post",
    data: param,
  });
}
// 设备列表
export function getCameraList(param) {
  return request({
    url: "/camera/queryCameraList",
    method: "get",
    params: param,
  });
}
// 账户列表
export function accountList(param) {
  return request({
    url: "/account/queryAccountList",
    method: "get",
    params: param,
  });
}
// 基本信息
export function queryProjectList(param) {
  return request({
    url: "/account/queryProjectList",
    method: "get",
    params: param,
  });
}
// 查询用户信息
export function getUserInfo(param) {
  return request({
    url: "/user/getUserInfo",
    method: "get",
    params: param,
  });
}
// 更新项目名称
export function updateProjectName(param) {
  return request({
    url: "/account/editProjectInfo",
    method: "post",
    data: param,
  });
}





// 获取服务器列表
export function serverList(param) {
  return request({
    url: "/v1/monitor/agent/list",
    method: "get",
    params: param,
  });
}
